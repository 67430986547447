import services from "./index";
import store from "@/store";

export default {
    getConfig() {
        return {
            headers: {
                //authorization: `Bearer ${store.getters.jwtToken}`,
                //"x-tenant": process.env.VUE_APP_TENANT,
            }
        };
    },
    create() {
        return services.http.post(`/v1.0/simulator/create`, {}, this.getConfig());
    },
    prepare(simulationId, model) {
        return services.http.post(`/v1.0/simulator/prepare/${simulationId}`, model, this.getConfig());
    },
    seed(simulationId, model) {
        return services.http.post(`/v1.0/simulator/seed/${simulationId}`, model, this.getConfig());
    },
    run(simulationId) {
        return services.http.post(`/v1.0/simulator/run/${simulationId}`, {}, this.getConfig());
    },
    restart(simulationId) {
        return services.http.post(`/v1.0/simulator/restart/${simulationId}`, {}, this.getConfig());
    },
    clean(simulationId) {
        return services.http.post(`/v1.0/simulator/clean/${simulationId}`, {}, this.getConfig());
    },

}